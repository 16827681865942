import { Button, IconButton, Skeleton, TextField } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {
  createTickerConnection,
  initializeTickerObserver,
  updateTicker
} from "../../api/ticker";
import { useDispatch, useSelector } from "react-redux";
import useApi from "../../hooks/useApi";
import CustomAlert from "../widgets/custom-alert";
import Card from "../widgets/card"
import useThemeBase from "../../hooks/useThemeBase";
import { createNotificationConnection, initializeNotificationObserver, updateNotification } from "../../api/push-notification";

const LoadingContainer = styled(Card)`
  display: flex;
  width: 100%;
  min-height: 100px;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
`;

const Body = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  row-gap: 20px;
  margin: auto;
  padding: 20px;
`;

const Actions = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  column-gap: 20px;
  justify-content: flex-end;
  align-items: center;
  position: relative;
`;

const EditLayer = styled.div`
  ${(props) =>
    props.open
      ? `
    display:flex;
    `
      : `
    display:none;
    `}
  width:100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
`;

const PushNotification = (props) => {
  const themeBase = useThemeBase()
  const { notificationStore } = useSelector((store) => store);
  const dispatch = useDispatch();

  const { notification } = notificationStore;

  const [newNotification, setNewNotification] = useState(notification);
  const [isEditable, setIsEditable] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [error, setError] = useState(null);

  const [{ loading }] = useApi(createNotificationConnection, {
    onSuccess: (res) => {
      if (res) initializeNotificationObserver(dispatch, res);
    },
    onError: (error) => setError(error),
  });

  const [updateNotificationApiProps] = useApi(updateNotification, {
    onSuccess: () => setShowSuccess(true),
    onError: (error) => setError(error),
    onlyOnEvent: true
  });

  const submitHandler = (e) => {
    e.preventDefault();
    updateNotificationApiProps.run({ message: newNotification }).then(() => setIsEditable(false));
  };

  const undoHandler = () => {
    setNewNotification(notification);
    setIsEditable(false);
  };

  useEffect(() => {
    setNewNotification(notification);
  }, [notification]);

  useEffect(() => {
  }, [showSuccess]);

  return loading ? (
    <LoadingContainer>
      <Skeleton sx={{ width: "100%", minHeight: "100px" }} />
    </LoadingContainer>
  ) : (
    <>
      <Card sx={{ position: "relative", background: themeBase.layer[1] }}>
        <Body onSubmit={submitHandler}>
          <EditLayer open={!isEditable}>
            <IconButton onClick={() => setIsEditable(true)}>
              <EditIcon />
            </IconButton>
          </EditLayer>
          <TextField
            multiline
            minRows={2}
            maxRows={4}
            label="Push Notification"
            variant="outlined"
            value={newNotification}
            onChange={(e) => setNewNotification(e.target.value)}
            disabled={!isEditable}
          />
          {isEditable && (
            <Actions>
              <Button type="button" variant="text" onClick={undoHandler}>
                Undo
              </Button>
              <Button
                type="submit"
                variant="contained"
                disabled={updateNotificationApiProps.loading}
              >
                {updateNotificationApiProps.loading ? "..." : "Push"}
              </Button>
            </Actions>
          )}
        </Body>
      </Card>
      <CustomAlert
        open={showSuccess}
        onClose={() => setShowSuccess(false)}
        duration={2000}
        type="success"
      >
        Notification pushed successfully.
      </CustomAlert>
      <CustomAlert
        open={!!error}
        onClose={() => setError(null)}
        duration={2000}
        type="error"
      >
        {error?.message || "Something went wrong."}
      </CustomAlert>
    </>
  );
};

export default PushNotification;
