import { Card, Grid, Snackbar } from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import LoginForm from "../forms/login-form";
import { useDispatch, useSelector } from "react-redux";
import LinearProgress from "@mui/material/LinearProgress";
import CustomAlert from "../widgets/custom-alert";
import Logo from "../common/logo";
import useApi from "../../hooks/useApi";
import { authLogin, authLoginSuccess } from "../../api/auth";
import useThemeBase from "../../hooks/useThemeBase";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  min-height: 100vh;
  width: 100%;
  justify-content: center;
  background: ${props => props.themeBase.layer[1]};
`;

const Alert = React.forwardRef((props, ref) => {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Login = () => {
  const themeBase = useThemeBase()
  const dispatch = useDispatch();
  const { authStore } = useSelector((store) => store);

  const [error, setError] = useState(null);

  const [authLoginApi] = useApi(authLogin, {
    onSuccess: (res) => {
      if (res) authLoginSuccess(dispatch);
    },
    onError: (error) => setError(error),
    onlyOnEvent: true
  });

  const loginHandler = (data) => {
    authLoginApi.run(data);
  };


  return (
    <Wrapper themeBase={themeBase}>
      <Grid
        container
        spacing={2}
        sx={{ minHeight: "100%", justifyContent: "center" }}
      >
        <Grid item xs={10} sm={6} md={5} lg={4} xl={3}>
          <Logo
            style={{
              maxWidth: "350px",
              display: "block",
              margin: "0 auto 20px auto"
            }}
          />
          <Card elevation={5} className="overlay" sx={{ background: themeBase.layer['2.5'], padding: "15px", position: "relative" }}>
            <div
              style={{
                position: "absolute",
                top: "0",
                left: "0",
                width: "100%"
              }}
            >
              {authLoginApi?.loading && <LinearProgress />}
            </div>
            <LoginForm onsubmit={loginHandler} />
          </Card>
        </Grid>
      </Grid>
      <CustomAlert
        open={!!error}
        onClose={() => setError(null)}
        type="error"
      >
        {error?.message}
      </CustomAlert>
    </Wrapper>
  );
};

export default Login;
