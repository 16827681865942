import { Card, LinearProgress, linearProgressClasses, Skeleton, Typography } from "@mui/material";
import React from "react";
import styled from "styled-components";
import mStyled from "@mui/material/styles/styled";
import Avatars from "../widgets/avatars";
import { purple } from "@mui/material/colors";
import useThemeBase from "../../hooks/useThemeBase";

const NoUsers = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 15px;
`;

const Grid = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  position:relative;
  padding:10px;
`;
const Content = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: Center;
`;
const RightContent = styled.div`
  display: flex;
  justify-content: flex-end;
  width: mx-content;
  height: 100%;
  align-items: Center;
`;
const LeftContent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: Center;
`;
const BarContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: Center;
  column-gap:10px;
`;

const AbsoluteContent = styled.div`
  position:absolute;
  top:0;
  right:0;
  display: flex;
  justify-content: flex-end;
  width: max-content;
  height: min-content;
  align-items: Center;
  padding:10px;
  z-index:1;
`;


const BorderLinearProgress = mStyled(LinearProgress)(({ theme }) => ({
  [`&.${linearProgressClasses.colorPrimary}`]: {
    height: "6px",
    backgroundColor: theme.palette.common.light_transparent,
    borderRadius: 5
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: "white"
  }
}));




const TopLocationCard = ({ location, id, openUsersList, liveUsers, totalLiveUsersCount, loading }) => {
  const themeBase = useThemeBase()
  const content = (
    <Grid
      onClick={openUsersList}
      style={{ cursor: "pointer" }}
    >
      <AbsoluteContent>
        {location.icon}
      </AbsoluteContent>
      <Content>
        <LeftContent>
          <Typography variant="h1" style={{ color: purple[500], marginRight: "10px" }}>{liveUsers.length}</Typography>
          <Typography variant="h5" style={{ color: "inherit" }}>
            User{liveUsers?.length > 1 && "s"} Live in {location?.name}
          </Typography>
        </LeftContent>
        <RightContent>
          <Avatars
            avatars={[...liveUsers.slice(0, 3)]}
            limit={3}
            total={liveUsers.length}
            location={location?.id}
          />
        </RightContent>
      </Content>
      <BarContainer>

        <BorderLinearProgress variant="determinate" value={totalLiveUsersCount > 0 ? Math.round((liveUsers.length / totalLiveUsersCount) * 100) : 0}
          sx={{ height: "6px", borderRadius: "30px", width: "100%" }}
        />
        <Typography variant="body2">{totalLiveUsersCount > 0 ? +((liveUsers.length / totalLiveUsersCount) * 100).toFixed(2) + "%" : 0 + "%"}</Typography>
      </BarContainer>
    </Grid>
  )

  return (
    <>
      <Card style={{ background: themeBase.colors[id + 1], color: "#222" }}>
        {loading ? <Skeleton sx={{ minHeight: "100px", margin: "auto 20px" }} /> : content}
      </Card>
    </>
  );
};

export default TopLocationCard;
