import { Card } from "@mui/material";
import React from "react";
import styled from "styled-components";
import useThemeBase from "../../hooks/useThemeBase";

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px 0 0 0;
`;

const Grid = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  position: relative;
`;
const Header = styled.div`
z-index:3;
  width: calc(100% - 100px);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${props => props.themeBase.spacing[2]};
  position: absolute;
  top: -15px;
  left: 50%;
  transform: translateX(-50%);
  background: ${props => props.themeBase.primary.main};
  box-shadow: ${props => props.themeBase.shadows.glow};
  border-radius: 4px;
  color: White;
  font-weight: light;
  font-size: 26px;
`;
const Body = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: Center;
  align-items: Center;
  padding: ${props => props.themeBase.spacing[2]};
  padding-top: 50px;
  flex-direction: column;
`;

const Footer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: Center;
  align-items: Center;
  padding: ${props => props.themeBase.spacing[2]};
`;


const CustomCard = ({ header, footer, children }) => {
  const themeBase = useThemeBase()
  return (
    <Container>
      <Grid>
        <Header themeBase={themeBase}>{header}</Header>
        <Card style={{background: themeBase.layer[1]}}>
          <Body themeBase={themeBase}>{children}</Body>
          {footer && <Footer themeBase={themeBase}>{footer}</Footer>}
        </Card>
      </Grid>
    </Container>
  );
};

export default CustomCard;
