import * as actionTypes from "../actionTypes";

const initialState = {
  authUser: {},
  loading: true,
  error: false
};


export const loginUserSuccess = (state, action) => {
  return {
    ...state,
    authUser: action.authUser,
    loading: false,
    error: false
  };
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.AUTH_ACTION_START:
      return { ...state, loading: true, error: false }

    case actionTypes.AUTH_ACTION_FAIL:
      return { ...state, loading: false, error: action.error }

    case actionTypes.LOGIN_USER_SUCCESS:
      return loginUserSuccess(state, action);

    default:
      return state;
  }
};

export default reducer;
