import { Avatar } from "@mui/material"
import React from "react"
import styled from "styled-components"

const backgrounds = ["#ff9090", "#99ccff", "#ff94f6"]

const AvatarsContainer = styled.div`
    width:max-content;
    height:min-content;
    display:flex;
    align-items:center;
`

const Avatars = ({ avatars, limit, total = 0 }) => {
    return <AvatarsContainer>
        {avatars?.map(({ url, fullName, firstName, lastName }, i) => (
            i < limit && <Avatar key={i} src={url} style={{ width: '26px', height: '26px', fontSize: "12px", background: backgrounds[i], marginLeft: i > 0 ? "-7px" : "0", border: "1px solid white" }} >
                {!url && !!fullName ?
                    fullName.split(" ")?.map(n => n.substring(0, 1).toUpperCase()) :
                    !url && !fullName && !!firstName && !lastName ?
                        firstName.substring(0, 1).toUpperCase() :
                        !url && !fullName && !!firstName && !!lastName ?
                            firstName.substring(0, 1).toUpperCase() + lastName.substring(0, 1).toUpperCase() :
                            "?"
                }
            </Avatar>
        ))}
        {total > limit && <Avatar style={{ width: '26px', height: '26px', fontSize: "12px", marginLeft: "-7px", border: "1px solid white" }} >
            {total - limit + "+"}
        </Avatar>}
    </AvatarsContainer>
}

export default Avatars