import AddIcon from "@material-ui/icons/Add";
import {
    Button,
    CircularProgress,
    Divider,
    Fab,
    FormControlLabel,
    IconButton,
    Skeleton,
    Stack,
    Switch,
    Typography
} from "@mui/material";
import React, { useEffect, useRef } from "react";
import { useState } from "react";
import { isMobile } from "react-device-detect";
import {
    createStaticQuestion,
    deleteStaticQuestion,
    getStaticQuestions,
    getStaticQuizConfig,
    updateStaticQuestion,
    updateStaticQuestionOrder,
    updateStaticQuizConfig
} from "../../api/quiz";
import useApi from "../../hooks/useApi";
import useThemeBase from "../../hooks/useThemeBase";
import CustomizedCard from "../widgets/card";
import CustomAlert from "../widgets/custom-alert";
import Popup from "../widgets/popup";
import Menu from "../widgets/menu";
import MoreVertIcon from "@material-ui/icons/MoreHoriz";
import StaticQuestionForm from "../forms/static-question-form";
import StaticQuizAnswers from "./static-quiz-answers";
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import styled from "styled-components";
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import { KeyboardArrowDown, KeyboardArrowUp } from "@material-ui/icons";


const Option = styled(Typography)`
  background: ${(props) => props.themeBase.layer[1]};
  border-radius: 4px;
  padding: 10px;
  width: ${isMobile ? "100%" : "max-content"};
  min-width: 200px;
`;
const OptionNumber = styled(Typography)`
  background: ${(props) => props.themeBase.layer[1]};
  border-radius: 4px;
  padding: 10px;
  width: max-content;
`;

const Column = styled(Stack)`
  flex-direction: column;
`;
const Row = styled(Stack)`
  flex-direction: row !important;
`;

const QuestionType = styled.div`
    display: flex;
    align-items:center;
    justify-content:center;
    width: max-content;
    white-space: nowrap;
    padding: 5px 10px;
    font-size: 12px;
    border-radius: ${isMobile ? "30px" : "0"};
    margin-bottom: ${isMobile ? "10px" : "0"};
`

const LoadingWrapper = styled.div`
  display: flex;
  width: 100%;
  height:100%;
  align-items:center;
  justify-content:center;
  position:absolute;
  top:0;left:0;
  background: rgba(0,0,0,.2);
  backdrop-filter:blur(5px);
  z-index:1;
`;


const QuestionsListUI = ({
    loading,
    questions,
    openEditFormHandler,
    deleteHandler,
    createQuestionLoading,
    updateQuestionLoading,
    openForm,
    setOpenForm,
    setEditQuestion,
    editQuestion,
    submitHandler,
    errorHandler,
    error,
    setError,
    success,
    setSuccess,
    updateStaticQuizConfig,
    staticQuizConfigLoading,
    staticQuizConfig,
    getQuestionsApi
}) => {

    const themeBase = useThemeBase();
    const [showStaticQuizAnswers, setShowStaticQuizAnswers] = useState(false);
    const [allowedMultiAttempts, setAllowedMultiAttempts] = useState(staticQuizConfig?.allowedMultiAttempts);
    const [currentQuestionOrder, setCurrentQuestionOrder] = useState(0)
    const [newQuestionOrder, setNewQuestionOrder] = useState(0)
    let questionCount = 0;

    const dragRef = useRef()

    const [updateStaticQuestionOrderApi] = useApi(updateStaticQuestionOrder, {
        onSuccess: (res) => {
            setSuccess("Questions order updated Successfully");
            getQuestionsApi.run();
            setCurrentQuestionOrder(0)
            setNewQuestionOrder(0)
        },
        onError: (error) => {
            setError(error)
        },
        onlyOnEvent: true
    });

    const options = (question) => {
        let optionsArray = [
            {
                label: "Edit",
                action: () => openEditFormHandler(question)
            },
            {
                label: "Delete",
                action: () => deleteHandler(question.id)
            }
        ]

        return optionsArray
    }

    const StaticQuizConfigUpdateHandler = async () => {
        if (staticQuizConfig?.allowedMultiAttempts === allowedMultiAttempts) return
        updateStaticQuizConfig({ allowedMultiAttempts })
    }

    const updateQuestionOrder = (currentOrder = currentQuestionOrder, newOrder = newQuestionOrder) => {

        if (!currentOrder || !newOrder || currentOrder === newOrder) {
            setCurrentQuestionOrder(0)
            setNewQuestionOrder(0)
            return
        }


        if (currentOrder < newOrder) {
            let questionsNeedsToBeUpdated = questions.filter(q => q.order >= currentOrder && q.order <= newOrder)
            questionsNeedsToBeUpdated = questionsNeedsToBeUpdated
                .map(q => {
                    if (q.order === currentOrder) q.order = newOrder
                    else q.order--
                    return q
                })
            updateStaticQuestionOrderApi.run(questionsNeedsToBeUpdated)
        } else if (currentOrder > newOrder) {
            let questionsNeedsToBeUpdated = questions.filter(q => q.order <= currentOrder && q.order >= newOrder)
            questionsNeedsToBeUpdated = questionsNeedsToBeUpdated
                .map(q => {
                    if (q.order === currentOrder) q.order = newOrder
                    else q.order++
                    return q
                })
            updateStaticQuestionOrderApi.run(questionsNeedsToBeUpdated)
        }
    }

    const dragStartHandler = (e, order) => {
        setTimeout(() => {
            e.target.style.display = "none"
        }, 0)
        setCurrentQuestionOrder(order)
    }

    const dragEndHandler = (e, order) => {
        e.target.style.display = "block"
        setTimeout(() => {
            updateQuestionOrder()
        }, 100)
    }

    const dragEnterHandler = (e, q, order) => {
        e.preventDefault()
        e.stopPropagation()
        order && setNewQuestionOrder(order)
        e.target.style.background = "blue"
    }
    const dragLeaveHandler = (e) => {
        e.preventDefault()
        e.stopPropagation()
        e.target.style.background = "none"
        console.log(e.target.style.background)
    }
    const dropHandler = (e) => {
        e.preventDefault()
        e.stopPropagation()
        e.target.style.background = "none"
        console.log(e.target.style.background)
    }
    const dragOverHandler = (e) => {
        e.preventDefault()
        e.stopPropagation()
    }

    const sortByArrowButtons = (currentOrder, newOrder) => {

        updateQuestionOrder(currentOrder, newOrder)
    }

    useEffect(() => {
        StaticQuizConfigUpdateHandler()
    }, [allowedMultiAttempts])

    useEffect(() => {
        setAllowedMultiAttempts(staticQuizConfig?.allowedMultiAttempts)
    }, [staticQuizConfig?.allowedMultiAttempts])

    return <>
        {loading ? (
            <Skeleton style={{ width: "100%", height: "100px" }} />
        ) : questions.length > 0 ? (
            <Column >
                {updateStaticQuestionOrderApi.loading && <LoadingWrapper><CircularProgress /></LoadingWrapper>}
                <Row justifyContent="flex-end" alignItems="center" gap="10px">
                    <Row alignItems="center">
                        {staticQuizConfigLoading && <CircularProgress size={20} />}
                        <FormControlLabel
                            disabled={staticQuizConfigLoading}
                            label="Allow Multiple Attempts"
                            control={<Switch checked={allowedMultiAttempts} />}
                            onChange={() => setAllowedMultiAttempts(prev => !prev)} />
                    </Row>
                    <Divider orientation="vertical" flexItem />
                    <Button color="secondary" onClick={() => setShowStaticQuizAnswers(true)}>Answers</Button>
                </Row>
                {questions?.map((q) => {
                    questionCount++;
                    return (
                        <>
                            <div
                                className="dragover"
                                style={{ height: "5px", width: "100%" }}
                                onDragEnter={(e) => dragEnterHandler(e, q, currentQuestionOrder < q.order ? q.order - 1 : q.order)}
                                onDragOver={e => e.preventDefault()}
                                onDragLeave={dragLeaveHandler}
                                onDrop={dropHandler}
                            ></div>
                            <div
                                draggable={!isMobile}
                                onDragStart={(e) => dragStartHandler(e, q.order)}
                                onDragEnd={(e) => dragEndHandler(e, q.order)}
                                style={{ cursor: !isMobile && "move" }}>
                                <CustomizedCard
                                    key={q.id + questionCount}
                                    style={{
                                        background: themeBase.layer[1],
                                        boxShadow: "none",
                                        padding: themeBase.spacing[2]
                                    }}
                                >
                                    <Stack
                                        direction={isMobile ? "column" : "row"}
                                        justifyContent="flex-start"
                                        columnGap={themeBase.spacing[2]}
                                    >
                                        <Stack
                                            direction={isMobile ? "row" : "column"}
                                            justifyContent={isMobile ? "center" : "space-between"}
                                            gap={themeBase.spacing[2]}
                                            minHeight={!isMobile && "100%"}
                                            position="relative"
                                            minWidth="max-content"
                                        >
                                            <Column
                                                style={{
                                                    background: themeBase.layer[1],
                                                    paddingTop: themeBase.spacing[2],
                                                    overflow: "hidden",
                                                    gap: !isMobile && "10px",
                                                    borderRadius: "4px",
                                                    alignItems: "center",
                                                    width: isMobile && "70%",
                                                    minWidth: !isMobile && "75px",
                                                    marginBottom: isMobile && themeBase.spacing[2]
                                                }}
                                            >
                                                <Typography variant="h5" style={{ whiteSpace: "nowrap" }}>
                                                    {"Q " + questionCount}
                                                </Typography>
                                                <QuestionType
                                                    style={{
                                                        background: themeBase.primary.main,
                                                        color: themeBase.primary.contrast,
                                                        width: !isMobile && "100%"
                                                    }}
                                                >
                                                    {q.questionType === "radio"
                                                        ? "Single Choice"
                                                        : q.questionType === "multiple"
                                                            ? "Multiple Choice"
                                                            : q.questionType === "slider"
                                                                ? "Slider"
                                                                : "Descriptive"}
                                                </QuestionType>
                                            </Column>
                                            {isMobile && (<>
                                                <div style={{ position: "absolute", top: 0, right: 0 }}>
                                                    <Menu
                                                        options={options(q)}
                                                    />
                                                </div>
                                                <div style={{ position: "absolute", top: 0, left: 0, display: "flex", flexDirection: "column", alignItems: "center" }}>
                                                    <IconButton onClick={() => sortByArrowButtons(q.order, q.order - 1)} disabled={q.order < 2}>
                                                        <KeyboardArrowUp />
                                                    </IconButton>
                                                    <IconButton onClick={() => sortByArrowButtons(q.order, q.order + 1)} disabled={q.order >= questions.length}>
                                                        <KeyboardArrowDown />
                                                    </IconButton>
                                                </div>
                                            </>
                                            )}
                                        </Stack>
                                        <Column
                                            rowGap={themeBase.spacing[2]}
                                            style={{ width: "100%" }}
                                        >
                                            <Row
                                                justifyContent="space-between"
                                                gap={themeBase.spacing[4]}
                                            >
                                                <Typography variant="body1">
                                                    <span>{q.question}</span>
                                                </Typography>
                                                {!isMobile && (
                                                    <Column alignItems="center" justifyContent="space-between" height="100%" gap="10px">
                                                        <div>
                                                            <Menu
                                                                btnProps={{
                                                                    variant: "contained",
                                                                    style: {
                                                                        background: themeBase.layer[1]
                                                                    }
                                                                }}
                                                                label={<MoreVertIcon />}
                                                                options={options(q)}
                                                            />
                                                        </div>
                                                        {/* <IconButton style={{ cursor: "grab" }} onMouseEnter={() => activateDrag(q.order)} onMouseLeave={() => deactivateDrag(q.order)}>
                                                            <DragIndicatorIcon />
                                                        </IconButton> */}
                                                    </Column>
                                                )}
                                            </Row>
                                            <Column rowGap={themeBase.spacing[2]}>
                                                {q.options?.length > 0 && (
                                                    <Typography
                                                        variant="body1"
                                                        color={themeBase.primary.main}
                                                    >
                                                        OPTIONS
                                                    </Typography>
                                                )}
                                                {q.options?.map((option, i) => (
                                                    <Row
                                                        key={option.value + i}
                                                        justifyContent="flex-start"
                                                        columnGap={themeBase.spacing[2]}
                                                    >
                                                        <OptionNumber themeBase={themeBase}>
                                                            {i + 1}
                                                        </OptionNumber>
                                                        <Option themeBase={themeBase}>{option.label}</Option>
                                                    </Row>
                                                ))}
                                            </Column>
                                        </Column>
                                    </Stack>
                                </CustomizedCard>
                            </div>
                            <div
                                className="dragover"
                                style={{ height: "5px", width: "100%" }}
                                onDragEnter={(e) => dragEnterHandler(e, q, currentQuestionOrder > q.order ? q.order + 1 : q.order)}
                                onDragLeave={dragLeaveHandler}
                                onDragOver={e => e.preventDefault()}
                                onDrop={dropHandler}
                            ></div>
                        </>
                    );
                })}
            </Column>
        ) : (
            <Column
                rowGap={themeBase.spacing[2]}
                justifyContent="center"
                alignItems="center"
                minHeight="100px"
            >
                <Typography>No question found for this Location</Typography>
                <Button
                    variant="outlined"
                    onClick={() => setOpenForm(true)}
                    startIcon={<AddIcon />}
                >
                    Create A Question First
                </Button>
            </Column>
        )}
        <Fab
            color="secondary"
            aria-label="add"
            style={{ position: "fixed", bottom: "2%", right: "2%" }}
            onClick={() => setOpenForm(true)}
        >
            <AddIcon />
        </Fab>
        <Popup
            loading={createQuestionLoading}
            open={openForm}
            title={
                !!Object.keys(editQuestion).length
                    ? "Edit Question"
                    : "Create New Question"
            }
            onClose={() => {
                setEditQuestion({});
                setOpenForm(false);
            }}
        >
            <div>
                <StaticQuestionForm
                    onSubmit={submitHandler}
                    onError={errorHandler}
                    loading={createQuestionLoading || updateQuestionLoading}
                    questionId={editQuestion?.id}
                    formData={!!Object.keys(editQuestion).length && editQuestion}
                />
            </div>
        </Popup>
        <CustomAlert
            open={!!success}
            onClose={() => setSuccess(null)}
            type="success"
        >
            {success}
        </CustomAlert>
        <CustomAlert open={!!error} onClose={() => setError(null)} type="error">
            {error?.message}
        </CustomAlert>
        <StaticQuizAnswers
            open={showStaticQuizAnswers}
            onClose={() => setShowStaticQuizAnswers(false)}
            title="Static Quiz Answers"
            questions={questions}
        />
    </>
}


const StaticQuestions = () => {
    const [openForm, setOpenForm] = useState(false);
    const [editQuestion, setEditQuestion] = useState({});
    const [questions, setQuestions] = useState([]);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const [staticQuizConfig, setStaticQuizConfig] = useState();

    const [getQuestionsApi] = useApi(getStaticQuestions, {
        onSuccess: (res) => {
            if (res)
                setQuestions(
                    res.map((q) => {
                        return {
                            ...q.attributes,
                            id: q.id
                        };
                    })
                );
        },
        onError: (error) => setError(error)
    });

    const [createQuestionApi] = useApi(createStaticQuestion, {
        onSuccess: (res) => {
            setSuccess("Question created Successfully");
            setOpenForm(false);
            getQuestionsApi.run();
        },
        onError: (error) => setError(error),
        onlyOnEvent: true
    });

    const [updateQuestionApi] = useApi(updateStaticQuestion, {
        onSuccess: (res) => {
            setSuccess("Question updated Successfully");
            setEditQuestion({});
            setOpenForm(false);
            getQuestionsApi.run();
        },
        onError: (error) => setError(error),
        onlyOnEvent: true
    });

    const [deleteQuestionApi] = useApi(deleteStaticQuestion, {
        onSuccess: (res) => {
            setSuccess("Question deleted Successfully");
            getQuestionsApi.run();
        },
        onError: (error) => setError(error),
        onlyOnEvent: true
    });

    const [getStaticQuizConfigApi] = useApi(getStaticQuizConfig, {
        onSuccess: (res) => {
            setStaticQuizConfig(res)
        },
        onError: (error) => setError(error)
    });

    const [updateStaticQuizConfigApi] = useApi(updateStaticQuizConfig, {
        onSuccess: (res) => {
            setSuccess("Satic Quiz configuration updated successfully!");
            getStaticQuizConfigApi.run()
        },
        onError: (error) => setError(error),
        onlyOnEvent: true
    });

    const submitHandler = (questionObject, questionId) => {
        const data = { ...questionObject };
        if (questionId) {
            updateQuestionApi.run({ ...data, id: questionId });
        } else {
            createQuestionApi.run(data);
        }
    };

    const openEditFormHandler = (question) => {
        let formData = { ...question };
        if (formData.options?.length > 0)
            formData.options = formData.options.map((qo) => qo.label);
        setEditQuestion(formData);
        setOpenForm(true);
    };

    const deleteHandler = (questionId) => {
        deleteQuestionApi.run(questionId);
    };


    const errorHandler = (msg) => {
        setError(new Error(msg));
    };




    return <QuestionsListUI
        loading={getQuestionsApi.loading}
        createQuestionLoading={createQuestionApi.loading}
        updateQuestionLoading={updateQuestionApi.loading}
        setOpenForm={setOpenForm}
        openForm={openForm}
        setEditQuestion={setEditQuestion}
        openEditFormHandler={openEditFormHandler}
        submitHandler={submitHandler}
        deleteHandler={deleteHandler}
        errorHandler={errorHandler}
        setError={setError}
        error={error}
        setSuccess={setSuccess}
        success={success}
        editQuestion={editQuestion}
        questions={questions}
        staticQuiz={true}
        updateStaticQuizConfig={(c) => updateStaticQuizConfigApi.run(c)}
        staticQuizConfigLoading={updateStaticQuizConfigApi.loading}
        staticQuizConfig={staticQuizConfig}
        getQuestionsApi={getQuestionsApi}
    />
};

export default StaticQuestions