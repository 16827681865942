import * as actionTypes from "../store/actionTypes"
import Parse from "./parse"


const getUserData = async (forced = false) => {
  let userData = null;
  if (forced) {
    userData = await Parse.Cloud.run('getUserData');
  }
  else {
    userData = Parse.User.current();
  }
  if (!userData) {
    throw new Error("Current User is null");
  }
  return userData;
};

export const getAllUsers = async () => await Parse.Cloud.run('fetchAllUserBasics')

export const getAllUsersSuccess = (dispatch, allUsers) => {
  dispatch({ type: actionTypes.SET_ALL_USERS, allUsers })
}


export const getLiveUsers = async () => {
  try {
    const userObj = new Parse.Query("isLive");
    let subscription = await userObj.subscribe();
    let users = await userObj.equalTo("isLive", true).find();
    users = users.map(user => {
      return { ...user.attributes, id: user.userId, username: user.attributes?.email || "" }
    })
    return { subscription, users };
  } catch (error) {
    console.error(error);
    throw error;
  }
}


export const handleLiveUsersSubscription = async (dispatch, locationStore, response) => {
  try {
    let { subscription, users } = response;
    dispatch({ type: actionTypes.SET_LIVE_USERS, liveUsers: users })

    const subscriptionActions = ["update", "create"];

    for (let action of subscriptionActions) {
      subscription.on(action, (obj) => {

        dispatch({ type: actionTypes.UPDATE_LIVE_USERS, userObject: obj })

      });
    }
  } catch (error) {
    console.error(error.message);
    dispatch({ type: actionTypes.LOCATION_ACTION_FAIL, error })
  }
}



export const getLocationSessions = async (location) => {

  try {
    const sessionQuery = new Parse.Query(location).limit(9999).descending('createdAt');
    let subscription = await sessionQuery.subscribe();
    let sessions = await sessionQuery.find();
    sessions = sessions.map(session => {
      return { ...session.attributes, id: session.attributes?.sessionId, username: session.attributes?.email || "" }
    })
    return { subscription, sessions };
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export const handleLocationSessionSubscription = async (dispatch, response, locationId) => {
  try {

    let { subscription, sessions } = response;
    dispatch({ type: actionTypes.SET_LOCATION_SESSIONS, locationId, locationSessions: sessions })

    const subscriptionActions = ["update", "create"];

    for (let action of subscriptionActions) {
      subscription.on(action, (obj) => {

        dispatch({ type: actionTypes.UPDATE_LOCATION_SESSIONS, locationId, sessionItem: obj.attributes })


      });
    }
  } catch (error) {
    console.error(error.message);
    dispatch({ type: actionTypes.LOCATION_ACTION_FAIL, error })
  }
}
