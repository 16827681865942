import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import { useDispatch, useSelector } from "react-redux";
import PowerIcon from "@mui/icons-material/PowerSettingsNew";
import { logout } from "../../api/auth";
import Logo from "../common/logo";
import { isMobile } from "react-device-detect";
import styled from "styled-components";
import useThemeBase from "../../hooks/useThemeBase";

const MyContainer = styled(Container)`
background: ${props => props.themeBase.layer[2]};
`

const Appbar = (props) => {
  const themeBase = useThemeBase()
  const { authStore, pageStore } = useSelector((store) => store);
  const dispatch = useDispatch();

  const logoutHandler = () => {
    logout(dispatch);
  };

  return (
    <AppBar position="sticky" style={{background:"none"}}>
      <MyContainer style={{ maxWidth: "unset" }} className="overlay" themeBase={themeBase}>
        <Toolbar disableGutters>
          {isMobile ? (
            <Box sx={{ flexGrow: 1, display: "flex", alignItems: "center" }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                color="primary"
                onClick={props.openSidebar}
              >
                <MenuIcon />
              </IconButton>
              <Logo style={{ maxHeight: "30px" }} />
            </Box>
          ) : (
            <Box sx={{ flexGrow: 1, display: "flex" }}>
              <Logo style={{ maxHeight: "30px" }} />
            </Box>
          )}

          <Box sx={{ flexGrow: 0 }}>
            <IconButton
              title="Logout"
              size="large"
              color="primary"
              onClick={logoutHandler}
              sx={{ fontSize: "16px" }}
            >
              <PowerIcon />
            </IconButton>
          </Box>
        </Toolbar>
      </MyContainer>
    </AppBar>
  );
};
export default Appbar;
