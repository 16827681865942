import { Grid, Typography } from "@mui/material";
import React, { useEffect } from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import * as actionTypes from "../store/actionTypes";
import Stats from "../components/stats";

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-start;
  padding: 20px;
  flex-direction: column;
  gap: 20px;
`;

const StatsPage = ({ navbar }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({
      type: actionTypes.SET_CURRENT_PAGE,
      currentPage: { id: "stats", navbar }
    });
  }, []);

  return (
    <Wrapper>
      <Grid
        container
        spacing={2}
        sx={{ minHeight: "100%", justifyContent: "center" }}
      >
        <Grid item xs={12}>
            <Stats />
        </Grid>
      </Grid>
    </Wrapper>
  );
};

export default StatsPage;
