export const AUTH_ACTION_START = "AUTH_ACTION_START";
export const AUTH_ACTION_FAIL = "AUTH_ACTION_FAIL";

export const LOGIN_USER_SUCCESS = "LOGIN_USER_SUCCESS";

export const SET_CURRENT_PAGE = "SET_CURRENT_PAGE";

export const SET_DEVICE_TYPE = "SET_DEVICE_TYPE";

export const LOCATION_ACTION_START = "LOCATION_ACTION_START";
export const LOCATION_ACTION_FAIL = "LOCATION_ACTION_FAIL";
export const SET_ALL_USERS = "SET_ALL_USERS";
export const SET_LIVE_USERS = "SET_LIVE_USERS";
export const UPDATE_LIVE_USERS = "UPDATE_LIVE_USERS";
export const SET_LOCATION_SESSIONS = "SET_LOCATION_SESSIONS";
export const UPDATE_LOCATION_SESSIONS = "UPDATE_LOCATION_SESSIONS";
export const CHECK_AND_UPDATE_LOCATION_SESSIONS = "CHECK_AND_UPDATE_LOCATION_SESSIONS";


export const FEEDBACK_ACTION_START = "FEEDBACK_ACTION_START";
export const FEEDBACK_ACTION_FAIL = "FEEDBACK_ACTION_FAIL";
export const SET_FEEDBACKS = "SET_FEEDBACKS";
export const UPDATE_FEEDBACKS = "UPDATE_FEEDBACKS";
export const SET_TOP_FEEDBACKS = "SET_TOP_FEEDBACKS";
export const UPDATE_TOP_FEEDBACKS = "UPDATE_TOP_FEEDBACKS";

export const TICKER_ACTION_START = "TICKER_ACTION_START";
export const TICKER_ACTION_FAIL = "TICKER_ACTION_FAIL";
export const SET_TICKER = "SET_TICKER";

export const NOTIFICATION_ACTION_START = "NOTIFICATION_ACTION_START";
export const NOTIFICATION_ACTION_FAIL = "NOTIFICATION_ACTION_FAIL";
export const SET_NOTIFICATION = "SET_NOTIFICATION";

export const STATS_ACTION_START = "STATS_ACTION_START";
export const STATS_ACTION_FAIL = "STATS_ACTION_FAIL";
export const SET_STATS = "SET_STATS";
export const UPDATE_STATS = "UPDATE_STATS";

export const QUIZ_ACTION_START = "QUIZ_ACTION_START";
export const QUIZ_ACTION_FAIL = "QUIZ_ACTION_FAIL";
export const SET_ANSWERS = "SET_ANSWERS";
export const UPDATE_ANSWERS = "UPDATE_ANSWERS";
export const SET_STATIC_ANSWERS = "SET_STATIC_ANSWERS";
export const UPDATE_STATIC_ANSWERS = "UPDATE_STATIC_ANSWERS";

export const SHARE_BUSINESS_OPPORTUNITY_ACTION_START = "SHARE_BUSINESS_OPPORTUNITY_ACTION_START";
export const SHARE_BUSINESS_OPPORTUNITY_ACTION_FAIL = "SHARE_BUSINESS_OPPORTUNITY_ACTION_FAIL";
export const SET_SHARE_BUSINESS_OPPORTUNITY = "SET_SHARE_BUSINESS_OPPORTUNITY";
export const UPDATE_SHARE_BUSINESS_OPPORTUNITY = "UPDATE_SHARE_BUSINESS_OPPORTUNITY";