
export const darkThemeBase = {
  primary: {
    main: "#ff617e",
    dark: "#df4763",
    light: "#ff8198",
    light2: "#ffbfcb",
    contrast: "#fff"
  },

  secondary: {
    main: "#ee96ff",
    dark: "#b872c6",
    light: "#f0a8ff",
    light2: "#f6caff",
    contrast: "#222"
  },

  success: {
    main: "#96d17e"
  },

  error: {
    rgb: "255, 143, 143",
    main: "#ff8f8f",
    dark: "#c55b5b",
    light: "#ffa7a7",
    contrast: "#fff"
  },

  common: {
    light: "rgba(81, 81, 81, 1)",
    text: "rgb(255, 255, 255)",
  },

  colors: {
    1: "#d8b6ff",
    2: "#97c8ff",
    3: "#ffe9ab",
    4: "#fdc799",
    5: "#00c39a",
    6: "#f8fd99",
    7: "#99fde6"
  },

  layer: {
    1: "rgba(147, 120, 175, 0.1)",
    2: "rgba(147, 120, 175, 0.2)",
    2.5: "rgba(147, 120, 175, 0.25)",
    3: "rgba(147, 120, 175, 0.3)",
    4: "rgba(147, 120, 175, 0.4)",
    5: "rgba(147, 120, 175, 0.5)"
  },

  main: {
    background: "#222",
    constrast: "#fff"
  },

  shadows: {
    glow: "0px 3px 7px rgb(255 45 85 / 10%), 0px 5px 10px rgb(255 45 85 / 10%), 0px 10px 20px rgb(255 45 85 / 10%)"
  },

  spacing: {
    1: "5px",
    2: '10px',
    3: '15px',
    4: '20px',
  }
};
export const lightThemeBase = {
  primary: {
    main: "#ff617e",
    dark: "#df4763",
    light: "#ff8198",
    light2: "#ffbfcb",
    contrast: "#fff"
  },

  secondary: {
    main: "#ee96ff",
    dark: "#b872c6",
    light: "#f0a8ff",
    light2: "#f6caff",
    contrast: "#222"
  },

  success: {
    main: "#96d17e"
  },

  error: {
    rgb: "255, 143, 143",
    main: "#ff8f8f",
    dark: "#c55b5b",
    light: "#ffa7a7",
    contrast: "#fff"
  },

  common: {
    light: "rgba(81, 81, 81, 1)",
    text: "rgb(255, 255, 255)",
  },

  colors: {
    1: "#d8b6ff",
    2: "#97c8ff",
    3: "#ffe9ab",
    4: "#fdc799",
    5: "#00c39a",
    6: "#f8fd99"
  },

  layer: {
    1: "rgba(147, 120, 175, 0.1)",
    2: "rgba(147, 120, 175, 0.2)",
    2.5: "rgba(147, 120, 175, 0.25)",
    3: "rgba(147, 120, 175, 0.3)",
    4: "rgba(147, 120, 175, 0.4)",
    5: "rgba(147, 120, 175, 0.5)"
  },

  main: {
    background: "#222",
    constrast: "#fff"
  },

  shadows: {
    glow: "0px 3px 7px rgb(255 45 85 / 10%), 0px 5px 10px rgb(255 45 85 / 10%), 0px 10px 20px rgb(255 45 85 / 10%)"
  },

  spacing: {
    1: "5px",
    2: '10px',
    3: '15px',
    4: '20px',
  }
};


export const USER_ROLE = {
  EXHIBITOR: 0,
  VISITOR: 1,
  HELPDESK: 2,
  ADMIN: 3
};

export const LOCATIONS = [
  { id: "lobby", name: "Lobby", sessionId: "lobby_sessions" },
  { id: "auditorium", name: "Auditorium", sessionId: "audi_sessions" },
  // { id: "live_exhibition", name: "Live Exhibition", sessionId: "live_exhibition_sessions" },
  // { id: "engagement_zone", name: "Engagement Zone", sessionId: "engagement_area_sessions" },
  // { id: "research_papers", name: "Research Papers", sessionId: "research_papers_sessions" },
  { id: "helpdesk", name: "Helpdesk", sessionId: "helpdesk_sessions" },
  {
    id: "selfie_booth1",
    name: "Selfie Booth",
    sessionId: "selfie_booth_sessions1"
  },
];

export const LOCAL_STORAGE_KEYS = {
  HAS_UPDATED: "hasUpdated",
}
