import * as actionTypes from "../actionTypes";

const initialState = {
  notification: "",
  loading: false,
  error: false
};




const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.NOTIFICATION_ACTION_START:
      return { ...state, loading: true, error: false };

    case actionTypes.NOTIFICATION_ACTION_FAIL:
      return { ...state, loading: false, error: action.error };

    case actionTypes.SET_NOTIFICATION:
      return { ...state, notification: action.notification };

    default:
      return state;
  }
};

export default reducer;
