import * as actionTypes from "../actionTypes";

const initialState = {
  ticker: "",
  loading: false,
  error: false
};




const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.TICKER_ACTION_START:
      return {...state, loading: true, error: false};

    case actionTypes.TICKER_ACTION_FAIL:
      return {...state, loading: false, error: action.error};
    
    case actionTypes.SET_TICKER:
      return {...state, ticker: action.ticker};

    default:
      return state;
  }
};

export default reducer;
