import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import Appbar from "./appbar";
import { getAppVersion, verifyAndSetAuthUser } from "../../api/auth";
import Sidebar from "./sidebar";
import Overlay from "../widgets/overlay";
import Spinner from "@mui/material/CircularProgress";
import { isMobile } from "react-device-detect";
import CustomAlert from "../widgets/custom-alert";
import { LOCAL_STORAGE_KEYS } from "../../utils/constants";

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
`;
const LoadingWrapper = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const RightSide = styled.div`
  width: 100%;
  height: 100%;
`;

const Layout = ({ children }) => {
  const { pageStore, authStore } = useSelector((store) => store);
  const dispatch = useDispatch();
  const [isSidebarOpen, setIsSidebarOpen] = useState(!isMobile);
  const [versionUpdateMessage, setVersionUpdateMessage] = useState();

  useEffect(() => {
    verifyAndSetAuthUser(dispatch);
  }, []);

  const openSidebar = () => {
    setIsSidebarOpen(true);
  };

  const closeSidebar = () => {
    setIsSidebarOpen(false);
  };

  const checkUpdateNeeded = async () => {
    try {

      let localVersion = process.env.REACT_APP_VERSION.toString();
      let globalVersion = await getAppVersion();
      let updated = JSON.parse(
        window.localStorage.getItem(LOCAL_STORAGE_KEYS.HAS_UPDATED)
      );

      if (updated) {
        let message = "";
        if (isMobile) {
          message = "Webapp has been updated, please close the app and open again";
        } else {
          message = "Webapp has been updated, please close the tab and open again";
        }
        window.localStorage.removeItem(LOCAL_STORAGE_KEYS.HAS_UPDATED);
        setTimeout(() => {
          setVersionUpdateMessage(message)
        }, 2000);
        
      } else if (
        !updated &&
        localVersion &&
        globalVersion &&
        localVersion != globalVersion
      ) {
        window.localStorage.setItem(
          LOCAL_STORAGE_KEYS.HAS_UPDATED,
          JSON.stringify(true)
        );
        window.location.reload();
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    checkUpdateNeeded();
  }, []);

  return authStore?.loading ? (
    <LoadingWrapper>
      <Spinner />
    </LoadingWrapper>
  ) : (
    <Wrapper>
      {pageStore?.currentPage?.navbar && (
        <>
          <Overlay isOpen={isSidebarOpen && isMobile} onClose={closeSidebar} />
          <Sidebar isSidebarOpen={isSidebarOpen} closeSidebar={closeSidebar} />
        </>
      )}
      <RightSide>
        {pageStore?.currentPage?.navbar && <Appbar openSidebar={openSidebar} />}
        {children}
      </RightSide>
      <CustomAlert
        open={!!versionUpdateMessage}
        onClose={() => setVersionUpdateMessage(null)}
        type="info"
        duration={2000}
      >
        {versionUpdateMessage}
      </CustomAlert>
    </Wrapper>
  );
};

export default Layout;
