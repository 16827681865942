import React from "react";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import useThemeBase from "../../hooks/useThemeBase";


const Theme = (props) => {

  const themeBase = useThemeBase()


  const darkTheme = {
    typography: {
      fontFamily: '"Montserrat", sans-serif',
      htmlFontSize: 18
    },
    palette: {
      mode: "dark",
      secondary: {
        main: themeBase.secondary.main,
        light: themeBase.secondary.light,
        dark: themeBase.secondary.dark,
        contrastText: themeBase.secondary.contrast
      },
      common: {
        light_transparent: "rgba(255, 255, 255, 0.5)",
        dark_transparent: "rgba(0, 0, 0, 0.5)"
      },
      error: {
        light: themeBase.error.light,
        main: themeBase.error.main,
        dark: themeBase.error.dark,
        contrastText: themeBase.error.contrast
      },
      primary: {
        main: themeBase.primary.main,
        light: themeBase.primary.light,
        dark: themeBase.primary.dark,
        contrastText: themeBase.primary.contrast
      }
    },
    components: {
      MuiListItemIcon: {
        styleOverrides: {
          root: {
            color: themeBase.common.text
          }
        }
      },

      MuiTable: {
        styleOverrides: {
          root: {
            borderColor: themeBase.common.text
          }
        }
      },
      MuiIconButton: {
        styleOverrides: {
          root: {
            color: themeBase.common.text
          }
        }
      }
    }
  };

  const theme = createTheme(darkTheme)

  return <ThemeProvider theme={theme}>{props.children}</ThemeProvider>;
};

export default Theme;
