import Parse from "./parse"
import * as actionTypes from "../store/actionTypes"

export const createNotificationConnection = async () => {
  try {
    let notificationObj = new Parse.Query("PushNotification");
    let initialNotification = await notificationObj.first();
    if (initialNotification) {
      initialNotification = initialNotification.attributes.message;
    }
    else {
      initialNotification = "";
    }
    let subscription = await notificationObj.subscribe();
    return { initialNotification, subscription };
  } catch (error) {
    console.error(error);
    throw error;
  }
}


export const initializeNotificationObserver = async (dispatch, response) => {
  try {
    let { subscription, initialNotification } = response

    dispatch({ type: actionTypes.SET_NOTIFICATION, notification: initialNotification })

    subscription.on("update", (object) => {
      let data = object.attributes;
      if (data.message) {
        dispatch({ type: actionTypes.SET_NOTIFICATION, notification: data.message })
      }
    });
  } catch (error) {
    console.error(error);
  }
};

export const updateNotification = async ({ message }) => {
  try {
    let notificationQuery = new Parse.Query("PushNotification");
    let notificationInstance = await notificationQuery.first();
    if (!notificationInstance) {
      notificationInstance = new Parse.Object('PushNotification');
    }
    notificationInstance.set('message', message);
    await notificationInstance.save();
    return true
  } catch (error) {
    console.error(error);
    throw error;
  }
}