import React from "react";
import routes from "./routes";
import { useSelector } from "react-redux";
import { Navigate, Route, Routes } from "react-router-dom";

const Routing = () => {
  const { authStore } = useSelector((store) => store);
  const returnUrl = window.location.href.split("?")[1]?.split("=")[1];

  const routeLinks = routes({
    loggedIn: !!authStore?.authUser?.sessionToken
  }).map((prop, key) => {
    return prop.redirect?.condition ? (
      <Route
        key={key}
        exact={true}
        path={prop.path}
        element={
          <Navigate
            to={
              returnUrl
                ? returnUrl
                : prop.redirect.from
                ? prop.redirect.pathTo + "?returnUrl=" + prop.redirect.from
                : prop.redirect.pathTo
            }
            navbar={!prop.redirect.hideNavBar}
          />
        }
      />
    ) : (
      <Route
        key={key}
        exact={true}
        path={prop.path}
        element={<prop.component navbar={!prop.hideNavBar} />}
      />
    );
  });

  return <Routes>{routeLinks}</Routes>;
};

export default Routing;
