import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import * as actionTypes from "../store/actionTypes"
import Login from "../components/login";


const LoginPage = ({navbar}) => {

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch({ type: actionTypes.SET_CURRENT_PAGE, currentPage: { id: "login", navbar } })
  }, [])

  return <Login />
};

export default LoginPage;
