import * as actionTypes from "../actionTypes";

const initialState = {
  currentPage:{
    id: "/",
    navbar: false
  },
  isMobile:false,
  themeMode: "dark"
};




const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_CURRENT_PAGE:
      return {...state, currentPage: action.currentPage};
  
    case actionTypes.SET_DEVICE_TYPE:
      return {...state, isMobile: action.isMobile};

    default:
      return state;
  }
};

export default reducer;
