import { Grid } from "@mui/material";
import React from "react";
import styled from "styled-components";
import OnlineUsersCard from "../dashboard/online-user-card";
import Ticker from "../dashboard/ticker";
import TopFeedbacks from "../dashboard/top-feedbacks";
import LocationGrid from "./locations-grid";
import PushNotification from "./push-notification";

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-start;
  padding: 20px;
  flex-direction: column;
  gap: 20px;
`;

const Dashboard = ({ navbar }) => {


  return (
    <Wrapper>
      <Grid
        container
        spacing={2}
        sx={{ minHeight: "100%", justifyContent: "center" }}
      >
        <Grid item xs={12}>
          <Ticker />
        </Grid>
      </Grid>
      <Grid
        container
        spacing={2}
        sx={{ minHeight: "100%", justifyContent: "center" }}
      >
        <Grid item xs={12}>
          <PushNotification />
        </Grid>
      </Grid>
      <Grid
        container
        spacing={2}
        sx={{ minHeight: "100%", justifyContent: "center" }}
      >
        <Grid item xs={12} >
          <OnlineUsersCard />
        </Grid>
      </Grid>
      <LocationGrid />
      <Grid
        container
        spacing={2}
        sx={{ minHeight: "100%", justifyContent: "center" }}
      >
        <Grid item xs={12}>
          <TopFeedbacks />
        </Grid>
      </Grid>

    </Wrapper>
  );
};

export default Dashboard;
