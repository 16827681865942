import * as actionTypes from "../actionTypes";

const initialState = {
  feedbacks: [],
  loading: false,
  error: false
};

const updateFeedbacks = (state, feedback) => {
  if (state.feedbacks.filter(fd => fd.id === feedback.id)?.length > 0) {
    return { ...state }
  }
  else {
    return {
      ...state,
      feedbacks: [feedback, ...state.feedbacks]
    }
  }
}


const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FEEDBACK_ACTION_START:
      return { ...state, loading: true, error: false }

    case actionTypes.FEEDBACK_ACTION_FAIL:
      return { ...state, loading: false, error: action.error }

    case actionTypes.SET_FEEDBACKS:
      return { ...state, loading: false, error: false, feedbacks: action.feedbacks }

    case actionTypes.UPDATE_FEEDBACKS:
      return updateFeedbacks(state, action.feedback)

    default:
      return state;
  }
};

export default reducer;
