import { Button, IconButton, Skeleton, TextField } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {
  createTickerConnection,
  initializeTickerObserver,
  updateTicker
} from "../../api/ticker";
import { useDispatch, useSelector } from "react-redux";
import useApi from "../../hooks/useApi";
import CustomAlert from "../widgets/custom-alert";
import Card from "../widgets/card"
import useThemeBase from "../../hooks/useThemeBase";

const LoadingContainer = styled(Card)`
  display: flex;
  width: 100%;
  min-height: 100px;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
`;

const Body = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  row-gap: 20px;
  margin: auto;
  padding: 20px;
`;

const Actions = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  column-gap: 20px;
  justify-content: flex-end;
  align-items: center;
  position: relative;
`;

const EditLayer = styled.div`
  ${(props) =>
    props.open
      ? `
    display:flex;
    `
      : `
    display:none;
    `}
  width:100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
`;

const Ticker = (props) => {
  const themeBase = useThemeBase()
  const { tickerStore } = useSelector((store) => store);
  const dispatch = useDispatch();

  const { ticker } = tickerStore;

  const [newTicker, setNewTicker] = useState(ticker);
  const [isEditable, setIsEditable] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [error, setError] = useState(null);

  const [ { loading }] = useApi(createTickerConnection, {
    onSuccess: (res) => {
      if (res) initializeTickerObserver(dispatch, res);
    },
    onError:(error) => setError(error),
  });

  const [ updateTickerApiProps] = useApi(updateTicker, {
    onSuccess:() => setShowSuccess(true),
    onError:(error) => setError(error),
    onlyOnEvent: true
  });
  
  const submitHandler = (e) => {
    e.preventDefault();
    updateTickerApiProps.run({ message: newTicker }).then(()=> setIsEditable(false));
  };

  const undoHandler = () => {
    setNewTicker(ticker);
    setIsEditable(false);
  };

  useEffect(() => {
    setNewTicker(ticker);
  }, [ticker]);

  useEffect(() => {
  }, [showSuccess]);

  return loading ? (
    <LoadingContainer>
      <Skeleton sx={{ width: "100%", minHeight: "100px" }} />
    </LoadingContainer>
  ) : (
    <>
      <Card sx={{ position: "relative", background: themeBase.layer[1] }}>
        <Body onSubmit={submitHandler}>
          <EditLayer open={!isEditable}>
            <IconButton onClick={() => setIsEditable(true)}>
              <EditIcon />
            </IconButton>
          </EditLayer>
          <TextField
            multiline
            minRows={2}
            maxRows={4}
            label="Ticker"
            variant="outlined"
            value={newTicker}
            onChange={(e) => setNewTicker(e.target.value)}
            disabled={!isEditable}
          />
          {isEditable && newTicker !== ticker && (
            <Actions>
              <Button type="button" variant="text" onClick={undoHandler}>
                Undo
              </Button>
              <Button
                type="submit"
                variant="contained"
                disabled={updateTickerApiProps.loading}
              >
                {updateTickerApiProps.loading ? "Updating..." : "Update"}
              </Button>
            </Actions>
          )}
        </Body>
      </Card>
      <CustomAlert
        open={showSuccess}
        onClose={() => setShowSuccess(false)}
        duration={2000}
        type="success"
      >
        Ticker updated Successfully.
      </CustomAlert>
      <CustomAlert
        open={!!error}
        onClose={() => setError(null)}
        duration={2000}
        type="error"
      >
        {error?.message || "Something went wrong."}
      </CustomAlert>
    </>
  );
};

export default Ticker;
