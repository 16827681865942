import { Grid } from "@mui/material";
import React from "react"
import styled from "styled-components";
import QuestionsCard from "./questions-card"

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-start;
  padding: 20px;
  flex-direction: column;
  gap: 20px;
`;

const Quiz = (props) => {
    return <Wrapper>
        <Grid
            container
            spacing={2}
            sx={{ minHeight: "100%", justifyContent: "center" }}
        >
            <Grid item xs={12}>
                <QuestionsCard />
            </Grid>
        </Grid>
    </Wrapper>
}

export default Quiz