import * as actionTypes from "../store/actionTypes"
import Parse from "./parse"

export const createTickerConnection = async () => {
    try {
      let tickerObj = new Parse.Query("Ticker");
      let initialTicker = await tickerObj.first();
      if (initialTicker) {
        initialTicker = initialTicker.attributes.message;
      }
      else {
        initialTicker = "";
      }
      let subscription = await tickerObj.subscribe();
      return { initialTicker, subscription };
    } catch (error) {
      console.error(error);
      throw error;
    }
  }


  export const initializeTickerObserver = async (dispatch, response) => {
    try {
      let { subscription, initialTicker } = response

      dispatch({type: actionTypes.SET_TICKER, ticker: initialTicker})
      
      subscription.on("update", (object) => {
        let data = object.attributes;
        if (data.message) {
           dispatch({type: actionTypes.SET_TICKER, ticker: data.message})
        }
      });
    } catch (error) {
      console.error(error);
    }
  };

export  const updateTicker = async (params) => await Parse.Cloud.run('updateTicker', params)