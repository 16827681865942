import {
    Skeleton,
    Stack,
} from "@mui/material";
import React, { useEffect } from "react";
import styled from "styled-components";
import {
    getStaticQuizAnswers,
    handleLiveStaticAnswersSubscription,
} from "../../api/quiz";
import useApi from "../../hooks/useApi";
import Popup from "../widgets/popup";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { useDispatch, useSelector } from "react-redux";
import { getAllUsers, getAllUsersSuccess } from "../../api/location";
import useThemeBase from "../../hooks/useThemeBase";


const Column = styled(Stack)`
  flex-direction: column;
`;
const Row = styled(Stack)`
  flex-direction: row !important;
`;

const CenteredPara = styled(Stack)`
  width: 100%;
  heigh: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const AnswerCell = styled.div`
  width: 100%;
  hieght: 100%;
  max-height: 100%;
  overflow: auto;
  white-space: pre-wrap;
  &::-webkit-scrollbar {
    width: 5px;
    height:5px;
  }
`;

let subscription

const StaticQuizAnswers = ({ questions, open, onClose, title }) => {
    const themeBase = useThemeBase();
    const dispatch = useDispatch();
    const { quizStore, locationStore, authStore } = useSelector((store) => store);
    const { staticAnswers } = quizStore;
    const { allUsers } = locationStore;

    const [getAnswersApi] = useApi(getStaticQuizAnswers, {
        onSuccess: (res) => {
            handleLiveStaticAnswersSubscription(res, dispatch);
            subscription = res.subscription
        },
        onlyOnEvent: true
    });
    const [getAllUsersApi] = useApi(getAllUsers, {
        onSuccess: (res) => {
            getAllUsersSuccess(dispatch, res, authStore?.authUser);
        },
        onlyOnEvent: true
    });

    useEffect(() => {
        if (!!open) {
            getAnswersApi.run();
            getAllUsersApi.run();
        }
        return () => subscription?.unsubscribe()
    }, [open]);



    const getColumns = () => {
        let columns = [
            {
                field: "fullName",
                minWidth: 150,
                headerName: "Name"
            },
            {
                field: "email",
                minWidth: 200,
                headerName: "Email"
            },

        ];

        questions.map((question, i) => {

            let column = {
                field: `answer${question.id}`,
                minWidth: 400,
                headerName: `Answer ${i + 1}`,
                valueGetter: (params) => {
                    let questionObject = params.row[`answer${question.id}`]
                    let selectedOptionNumber
                    let selectedOption
                    let selectedOptionsNumber = []
                    let selectedOptions = []
                    switch (questionObject?.question.questionType) {
                        case "text":
                            return questionObject?.answer[0] !== null ?
                                questionObject?.answer[0] : 'Not Attempted'

                        case "slider":
                            return questionObject?.answer[0] !== null ?
                                questionObject?.answer[0] : 'Not Attempted'

                        case "radio":
                            selectedOption = questionObject?.question.options.filter((option, i) => {
                                if (questionObject?.answer.includes(option.value)) selectedOptionNumber = i + 1
                                return questionObject?.answer.includes(option.value)
                            })[0]
                            return selectedOption ? `Option ${selectedOptionNumber}: ${selectedOption?.label}` : 'Not Attempted'

                        case "multiple":
                            selectedOptions = questionObject?.question.options.filter((option, i) => {
                                if (questionObject?.answer.includes(option.value)) selectedOptionsNumber.push(i + 1)
                                return questionObject?.answer.includes(option.value)
                            })
                            return selectedOptions.length ? selectedOptions?.map((option, i) => `option ${selectedOptionsNumber[i]}: ${option.label}`)?.join(", ") : 'Not Attempted'

                        default:
                            return "";
                    }
                },
                renderCell: (params) => {
                    let questionObject = params.row[`answer${question.id}`]
                    let selectedOptionNumber
                    let selectedOption
                    let selectedOptionsNumber = []
                    let selectedOptions = []
                    switch (questionObject?.question.questionType) {
                        case "text":
                            return <AnswerCell>{
                                questionObject?.answer[0] !== null ?
                                    questionObject?.answer[0] :
                                    <div style={{ color: themeBase.error.main }}>Not Attempted</div>
                            }</AnswerCell>;

                        case "slider":
                            return <AnswerCell>{
                                questionObject?.answer[0] !== null ?
                                    questionObject?.answer[0] :
                                    <div style={{ color: themeBase.error.main }}>Not Attempted</div>
                            }</AnswerCell>;

                        case "radio":
                            selectedOption = questionObject?.question.options.filter((option, i) => {
                                if (questionObject?.answer.includes(option.value)) selectedOptionNumber = i + 1
                                return questionObject?.answer.includes(option.value)
                            })[0]
                            return (
                                <AnswerCell>
                                    {selectedOption ? <div>
                                        Option {selectedOptionNumber}: {selectedOption?.label}
                                    </div> :
                                        <div style={{ color: themeBase.error.main }}>Not Attempted</div>}
                                </AnswerCell>
                            );
                        case "multiple":
                            selectedOptions = questionObject?.question.options.filter((option, i) => {
                                if (questionObject?.answer.includes(option.value)) selectedOptionsNumber.push(i + 1)
                                return questionObject?.answer.includes(option.value)
                            })
                            return (
                                <AnswerCell>
                                    {
                                        selectedOptions.length ?
                                            selectedOptions?.map((option, i) => <div>option {selectedOptionsNumber[i]}: {option.label}</div>) :
                                            <div style={{ color: themeBase.error.main }}>Not Attempted</div>
                                    }
                                </AnswerCell>
                            );

                        default:
                            return "";
                    }
                }
            }

            columns.push(column)
        })
        columns.push({
            field: "createdAt",
            minWidth: 150,
            headerName: "Created At",
            renderCell: (params) => {
                const date = new Date(params.row.createdAt).toLocaleDateString() + ' ' + new Date(params.row.createdAt).toLocaleTimeString()
                return date
            }
        })
        return columns
    }

    return (
        <Popup open={open} onClose={onClose} title={title}>
            <div style={{ width: "100%", height: "70vh" }}>
                {getAnswersApi.loading ? (
                    <Skeleton style={{ minHeight: "100px" }} />
                ) : staticAnswers?.length < 1 ? (
                    <CenteredPara>
                        <p>No one attempted this quiz yet.</p>
                    </CenteredPara>
                ) : (
                    <DataGrid
                        rows={staticAnswers.map((ans) => {
                            let answersColumns = {}
                            for (let i = 0; i < questions.length; i++) {
                                answersColumns = {
                                    ...answersColumns,
                                    [`answer${questions[i].id}`]: ans.quiz.filter(quiz => quiz.question.id === questions[i].id)[0]
                                }
                            }
                            return {
                                ...ans,
                                fullName: allUsers
                                    .filter((user) => user.email === ans.userId)
                                    ?.map((user) => user.firstName + " " + (user.lastName || ""))[0],
                                email: ans.userId,
                                ...answersColumns
                            };
                        })}
                        columns={getColumns()}
                        components={{
                            Toolbar: GridToolbar
                        }}
                        componentsProps={{
                            toolbar: {
                                csvOptions: {
                                    fileName: title,
                                }
                            }
                        }}
                    />
                )}
            </div>
        </Popup>
    );
};

export default StaticQuizAnswers