import { Typography } from "@mui/material";
import React from "react";
import useThemeBase from "../../hooks/useThemeBase";


const FormError = ({children}) => {
  const themeBase = useThemeBase()
  return (
    <Typography 
    variant="subtitle2"
    sx={{
        width: "100%",  
        background: `rgba(${themeBase.error.rgb}, 0.1)`,
        border:`1px solid ${themeBase.error.main}`,
        borderRadius: "4px",
        padding:"3px 6px",
        color: themeBase.error.main
    }}
    >
        {children}
    </Typography>
  );
}

export default FormError;
