import React, { useEffect, useState } from "react";

const useApi = (
  api,
  { params = null, onSuccess = null, onError = null, onlyOnEvent = false, onDestroy } = {}
) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [response, setResponse] = useState(null);

  const fetchApi = async (newParams) => {
    setLoading(true);
    try {
      const response = newParams
        ? await api(newParams)
        : params
          ? await api(params)
          : await api();
      setLoading(false);
      setError(null);
      setResponse(response);
      if (onSuccess) onSuccess(response);
    } catch (error) {
      setLoading(false);
      setError(error);
      setResponse(null);
      if (onError) onError(error);
    }
  };

  useEffect(() => {
    if (!onlyOnEvent) fetchApi();
    return () => !!onDestroy && onDestroy()
  }, []);

  const clearError = () => {
    setError(null);
  };

  return [
    {
      loading,
      error,
      run: fetchApi,
      clearError
    },
    response
  ];
};

export default useApi;
