import React, { useEffect, useState } from "react";
import Popup from "../widgets/popup";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import styled from "styled-components";
import { Avatar } from "@mui/material";
import Timer from "../widgets/Timer";
import useThemeBase from "../../hooks/useThemeBase";
import { getDurationOfVisitor } from "../../utils/helpers";

const LiveBadge = styled.span`
  position: absolute;
  bottom: 0;
  right: 0;
  width: 10px;
  height: 10px;
  background: ${props => props.themeBase.success.main};
  border-radius: 50%;
`;
const RelativeContainer = styled.span`
  position: relative;
`;

const LocationVisitorsList = ({ usersList, open, onClose, title, location }) => {
  const themeBase = useThemeBase()
  const [usersRows, setUsersRows] = useState([]);

  useEffect(() => {
    let updatedUsersList = usersList?.map((user) => {
      return {
        ...user
        // iconLabel:
        //   user.firstName.substring(0, 1).toUpperCase() +
        //   user.lastName.substring(0, 1).toUpperCase()
      };
    });
    updatedUsersList?.sort((a, b) => Number(b.isLive) - Number(a.isLive))
    setUsersRows(updatedUsersList);
  }, [usersList]);

  const columns = [
    {
      field: "iconLable",
      minWidth: 70,
      disableExport: true,
      renderCell: (params) => {
        return (
          <RelativeContainer>
            <Avatar
              sizes="small"
              style={{ background: themeBase.primary.light2 }}
            >
              {params.row.fullName
                .split(" ")
                ?.map((n, i) => i < 2 && n.substring(0, 1).toUpperCase())}
            </Avatar>
            {params.row.isLive && <LiveBadge themeBase={themeBase}></LiveBadge>}
          </RelativeContainer>
        );
      },
      headerName: ""
    },
    {
      field: "fullName",
      minWidth: 150,
      headerName: "Name"
    },
    {
      field: "isLive",
      minWidth: 150,
      headerName: "Time Spent (hh:mm:ss)",
      valueGetter: (params) => {
        return getDurationOfVisitor({
          isLive: params.row.isLive,
          duration: params.row.duration,
          startTime: params.row.updatedAt
        })
      },
      renderCell: (params) => {
        return (
          <Timer
            style={{ color: params.row.isLive && themeBase.success.main }}
            isLive={params.row.isLive}
            duration={params.row.duration}
            startTime={params.row.updatedAt}
          />
        );
      }
    },
    {
      field: "email",
      minWidth: 250,
      headerName: "Email"
    },
  ];

  return (
    <Popup open={open} onClose={onClose} title={title}>
      <div style={{ width: "100%", height: "70vh" }}>
        <DataGrid
          rows={usersRows}
          columns={columns}
          components={{
            Toolbar: GridToolbar
          }}
          componentsProps={{
            toolbar: {
              csvOptions: {
                fileName: `${location} Users Current Status`,
              }
            }
          }}
        />
      </div>
    </Popup>
  );
};

export default LocationVisitorsList;
