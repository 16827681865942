import * as actionTypes from "../actionTypes";

const initialState = {
  stats: [],
  loading: false,
  error: false
};



const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.STATS_ACTION_START:
      return { ...state, loading: true, error: false }

    case actionTypes.STATS_ACTION_FAIL:
      return { ...state, loading: false, error: action.error }

    case actionTypes.SET_STATS:
      return { ...state, loading: false, error: false, stats: action.stats }

    case actionTypes.UPDATE_STATS:
      return { 
        ...state, 
        loading: false, 
        error: false, 
        stats: [ action.statItem, ...action.stats ]
      }

    default:
      return state;
  }
};

export default reducer;
