import {
  Card,
  Hidden,
  LinearProgress,
  linearProgressClasses,
  Typography
} from "@mui/material";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {
  getLiveUsers,
  handleLiveUsersSubscription
} from "../../api/location";
import useApi from "../../hooks/useApi";
import Avatars from "../widgets/avatars";
import OnlineUsersList from "./online-users-list";
import { useSelector, useDispatch } from "react-redux/es/exports";
import mStyled from "@mui/material/styles/styled";
import OnlinePredictionIcon from "@mui/icons-material/OnlinePrediction";
import useThemeBase from "../../hooks/useThemeBase";


const Grid = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  position: relative;
  padding: 10px;
`;
const Content = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: Center;
`;
const RightContent = styled.div`
  display: flex;
  justify-content: flex-end;
  width: max-content;
  height: 100%;
  align-items: Center;
  position: absolute;
  right: 0;
  bottom: 0;
`;
const AbsoluteContent = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  justify-content: flex-end;
  width: max-content;
  height: min-content;
  align-items: Center;
  padding: 10px;
  z-index: 1;
`;
const LeftContent = styled.div`
  width: calc(100% - 200px)
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: Center;
`;

const LeftContentLeft = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
`;

const Count = styled(Typography)`
  // background: white;
  // border-radius: 50%;
  // width: 100px;
  // height: 100px;
  // color: var(--primary_color);
  // display: flex;
  // align-items: center;
  // justify-content: center;
  // margin: 0 10px 10px 0 !important;
  // margin: 10px !important;
  margin-right: 10px !important;
  color: white;
  ${(props) =>
    props.countlength === 1
      ? `
  font-size: 5.33rem !important;
  `
      : props.countlength === 2
        ? `
  font-size: 4.33rem !important;
  `
        : `
  font-size: 3.33rem !important;
  `}
`;


const OnlineUsersCard = () => {
  const themeBase = useThemeBase()
  const { locationStore } = useSelector((store) => store);
  const dispatch = useDispatch();
  const { liveUsers } = locationStore;

  const [liveUsersApiProps] = useApi(getLiveUsers, {
    onSuccess: (res) => {
      if (res) handleLiveUsersSubscription(dispatch, locationStore, res);
    }
  });

  const [isOnlineUsersListOpen, setIsOnlineUsersListOpen] = useState(false);
  const [liveUsersCountLength, setLiveUsersCountLength] = useState(1);

  const closeOnlineUsersList = (data) => {
    setIsOnlineUsersListOpen(false);
  };

  let avatars = [];
  for (let i = 0; i < 3; i++) {
    if (i < 3 && !!liveUsers[i])
      avatars.push({
        ...liveUsers[i],
        name: liveUsers[i].firstName + " " + (liveUsers[i].lastName || "")
      });
  }

  useEffect(() => {
    setLiveUsersCountLength(liveUsers.length.toString().length);
  }, [liveUsers.length])



  const content = (
    <Grid
      onClick={() => liveUsers.length && setIsOnlineUsersListOpen(true)}
      style={{ cursor: liveUsers.length && "pointer" }}
    >
      <Content>
        <AbsoluteContent>
          <OnlinePredictionIcon style={{ color: "#222" }} />
        </AbsoluteContent>
        <LeftContent>
          <Count variant="h1" countlength={liveUsersCountLength}>
            {liveUsers.length}
          </Count>
          <LeftContentLeft>
            <Typography variant="h5">
              User{liveUsers.length > 1 && "s"} Online
            </Typography>
            <Avatars avatars={avatars} limit={3} />
          </LeftContentLeft>
        </LeftContent>
        <Hidden smDown>
          <RightContent>
            <img src="./images/online_users.svg" style={{ width: "200px" }} />
          </RightContent>
        </Hidden>
      </Content>
    </Grid>
  );

  return (
    <>
      <OnlineUsersList
        open={isOnlineUsersListOpen}
        onClose={closeOnlineUsersList}
        title="All Users"
      />
      <Card
        sx={{
          background: themeBase.primary.main,
          color: "white",
          boxShadow: themeBase.shadows.glow
        }}
      >
        {liveUsersApiProps.loading ? (
          <LinearProgress style={{ minHeight: "100px", width: "100%" }} />
        ) : (
          content
        )}
      </Card>
    </>
  );
};

export default OnlineUsersCard;
