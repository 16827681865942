import {
    Skeleton,
    Stack,
} from "@mui/material";
import React, { useEffect } from "react";
import styled from "styled-components";
import {
    getAnswers,
    handleLiveAnswersSubscription,
} from "../../api/quiz";
import useApi from "../../hooks/useApi";
import Popup from "../widgets/popup";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { useDispatch, useSelector } from "react-redux";
import { getAllUsers, getAllUsersSuccess } from "../../api/location";
import { convertIdToString } from "../../utils/helpers";

const Column = styled(Stack)`
  flex-direction: column;
`;

const CenteredPara = styled(Stack)`
  width: 100%;
  heigh: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const AnswerCell = styled.div`
  width: 100%;
  hieght: 100%;
  max-height: 100%;
  overflow: auto;
  white-space: pre-wrap;
  &::-webkit-scrollbar {
    width: 5px;
    height:5px;
  }
`;

let subscription

const QuizAnswers = ({ questionId, open, onClose, title }) => {
    const dispatch = useDispatch();
    const { quizStore, locationStore, authStore } = useSelector((store) => store);
    const { answers } = quizStore;
    const { allUsers } = locationStore;

    const [getAnswersApi] = useApi(getAnswers, {
        onSuccess: (res) => {
            handleLiveAnswersSubscription(res, dispatch);
            subscription = res.subscription
        },
        onlyOnEvent: true
    });
    const [getAllUsersApi] = useApi(getAllUsers, {
        onSuccess: (res) => {
            getAllUsersSuccess(dispatch, res, authStore?.authUser);
        },
        onlyOnEvent: true
    });

    useEffect(() => {
        if (!!open) {
            getAnswersApi.run(questionId);
            getAllUsersApi.run();
        }
        return () => subscription?.unsubscribe()
    }, [open]);

    const columns = [
        {
            field: "fullName",
            minWidth: 150,
            headerName: "Name"
        },
        {
            field: "email",
            minWidth: 200,
            headerName: "Email"
        },
        {
            field: "answer",
            minWidth: 400,
            headerName: "Answer",
            valueGetter: (params) => {
                switch (params.row.question.questionType) {
                    case "text":
                        return params.row.answer[0]

                    case "radio":
                        return params.row.question.options?.filter((option, i) => params.row?.answer.includes(option.value))[0].label

                    case "multiple":
                        return params.row.question.options?.filter((option, i) => params.row?.answer.includes(option.value))?.map(ans => ans.label).join(", ")

                    default:
                        return "";
                }
            },
            renderCell: (params) => {

                switch (params.row.question.questionType) {
                    case "text":
                        return <AnswerCell>{params.row.answer[0]}</AnswerCell>;

                    case "radio":
                        return (
                            <AnswerCell>
                                <span>{params.row.question.options?.filter((option, i) => params.row?.answer.includes(option.value))[0].label}</span>
                            </AnswerCell>
                        );

                    case "multiple":
                        return (
                            <AnswerCell>
                                <Column>
                                    <span>Selected Options: </span>
                                    {params.row.question.options?.filter((option, i) => params.row?.answer.includes(option.value))?.map(ans => <div>{ans.label}</div>)}
                                </Column>
                            </AnswerCell>
                        );

                    default:
                        return "";
                }
            }
        }
    ];

    return (
        <Popup open={open} onClose={onClose} title={title}>
            <div style={{ width: "100%", height: "70vh" }}>
                {getAnswersApi.loading ? (
                    <Skeleton style={{ minHeight: "100px" }} />
                ) : answers?.length < 1 ? (
                    <CenteredPara>
                        <p>No one answered this question yet.</p>
                    </CenteredPara>
                ) : (
                    <DataGrid
                        rows={answers.map((ans) => {
                            return {
                                ...ans,
                                fullName: allUsers
                                    .filter((user) => user.email === ans.userId)
                                    ?.map((user) => user.firstName + " " + (user.lastName || ""))[0],
                                email: ans.userId
                            };
                        })}
                        columns={columns}
                        components={{
                            Toolbar: GridToolbar
                        }}
                        componentsProps={{
                            toolbar: {
                                csvOptions: {
                                    fileName: title,
                                }
                            }
                        }}
                    />
                )}
            </div>
        </Popup>
    );
};

export default QuizAnswers